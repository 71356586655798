var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gr-border container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-md-6"},[_vm._m(4),(_vm.$isHSIndRegActive)?_c('p',[_c('strong',[_vm._v("Registration: ")]),_c('router-link',{attrs:{"to":{name: 'register'}}},[_vm._v("Registration Open")])],1):_c('p',[_c('strong',[_vm._v("Registration: ")]),_vm._v("Registration Closed")]),_vm._m(5),_vm._m(6),_vm._m(7)]),_vm._m(8),_vm._m(9),_c('div',{staticClass:"col-md-6"},[_vm._m(10),(_vm.$isMSIndRegActive)?_c('p',[_c('strong',[_vm._v("Registration: ")]),_c('router-link',{attrs:{"to":{name: 'register'}}},[_vm._v("Registration Open")])],1):_c('p',[_c('strong',[_vm._v("Registration: ")]),_vm._v("Registration Closed")]),_vm._m(11),_vm._m(12),_vm._m(13)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center"},[_c('h1',[_c('strong',[_vm._v("About Goodyear's STEM Career Day")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('p',[_vm._v("For more than 25 years, Goodyear has engaged with Northeast Ohio students through our annual STEM Career Days. During these events, Goodyear aims to inspire students to reach their full potential and prepare for careers in science, technology engineering and mathematics.")]),_c('p',[_vm._v("Through interactive hands-on activities, engaging speaker sessions, speed mentoring and more at both the middle school and high school events, students interact with Goodyear associates and local STEM professionals to learn about opportunities in STEM. "),_c('a',{attrs:{"href":"https://www.youtube.com/shorts/up_lg1XTzcs","target":"_blank"}},[_vm._v("Watch this video to learn more!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 secondary text-banner gr-bak text-center"},[_c('h2',[_vm._v("High School Event")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-center"},[_c('img',{attrs:{"src":require("../assets/img/about/hs-stem-day-2024.jpg"),"alt":"Students at High School STEM Career Day"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Who:")]),_vm._v(" Students in Grades 9 through 12")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("What:")]),_vm._v(" Goodyear's STEM Career Day High School Event highlights career opportunities in STEM. Students will participate in presentations from professionals in the field, a Rube Goldberg Competition, tours of the University of Akron and professional mentoring! Up for a challenge? Get a group together to build a Rube Goldberg Machine and compete in the Rube Goldberg Challenge"),_c('sup',[_c('small',[_vm._v("®")])]),_vm._v("! We'll see you there!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Where:")]),_vm._v(" The University of Akron")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("When:")]),_vm._v(" February 8, 2025")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 secondary text-banner gr-bak text-center"},[_c('h2',[_vm._v("Middle School Event")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-center"},[_c('img',{attrs:{"src":require("../assets/img/about/middle-school-stem-day-2024.jpg"),"alt":"Students at Middle School STEM Career Day"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Who:")]),_vm._v(" Students in Grades 6 through 8")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("What:")]),_vm._v(" Goodyear's STEM Career Day Middle School Event brings students together to have fun while highlighting many opportunities in STEM. Students will participate in a hands-on engineering activity, various STEM-related interactive activities, an informational fair, and more! Up for a challenge? Get a group together to participate in the Rube Goldberg Machine Challenge"),_c('sup',[_c('small',[_vm._v("®")])]),_vm._v("! We'll see you there!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Where: ")]),_vm._v("To Be Confirmed")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("When: ")]),_vm._v(" April 5, 2025")])
}]

export { render, staticRenderFns }